@import "../../global.scss";

.loading_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading {
    width: 2.5rem;
    height: 2.5rem;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 2s linear infinite;
  }
  .loading_logo {
    width: 80px;
    animation: growShrink 1s infinite alternate;
  }
}

.recentOrders {
  .card-header {
    padding: 0.5rem !important;
    display: flex;
    justify-content: space-between;
  }
}

.rewards_container {
  margin: 0.25rem 0;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid gray;
  cursor: pointer;

  a{
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    width: 100% !important;
  }

  .line {
    height: 106px;
    width: 8px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .text_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100% !important;

    .text {
      padding: 0.25rem;
      height: 106px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
  
      .reward_top {
        font-size: 0.975rem;
        font-weight: 700;
      }
  
      .reward_bottom {
        font-size: 0.825rem;
        font-style: italic;
      }
    }
  }

  svg {
    height: 50px;
    width: 40px;
  }
}

@keyframes growShrink {
  0% {
    transform: scale(1); /* Original size */
  }
  100% {
    transform: scale(1.5); /* Scaled size (e.g., 150% of original) */
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@import "../../global.scss";

.navbar {
  padding: 0;
}

.topbar {
  padding: 0 0.75rem;
  height: 60px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $mainGray;
  background-color: $mainColor;
  color: white;

  .left {
    font-weight: 600;
    font-size: 1rem;

    cursor: pointer;

    a {
      color: white;
      text-decoration: none;
    }
  }

  .right {
    cursor: pointer;
    align-items: center;
    justify-content: center;

    svg {
      height: 50px;
      width: 50px;
      padding: 5px;
    }

    .dropdown {
      border-top: 1px solid gray;
      position: absolute;
      width: calc(100%);
      left: 0vw;
      display: flex;
      justify-content: center;
      background-color: $mainColor;
      z-index: 2;

      ul {
        .nav_item {
          list-style-type: none;
          padding: 15px 0px;
          font-size: 1rem;
          font-weight: 700;
          padding-left: 1rem;
          color: white;
          border-bottom: 1px solid white;
          text-decoration: none;
          display: list-item;
          text-align: -webkit-match-parent;
        }
        // li{
        //     padding-left: 1rem;
        //     font-weight: 700;

        //     a{
        //       text-decoration: none;
        //       color: white;
        //     }
        // }

        li:last-child {
          border: none !important;
        }
      }
    }
  }
}

.welcome {
  background-color: $mainColorLight;

  img {
    width: 36vw;
  }

  .discount {
    justify-content: center;
    margin: 0 auto;

    .message {
      font-size: 0.9rem;
    }
    .endDate {
      font-size: 0.8rem;
    }
  }
  .address_container {
    margin: 0 auto;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    max-width: 90%;
  }
}


@include desktop {
  .navbar {
    padding: 0;
    .topbar {
      .left {
        a {
          font-size: 1.3rem;
        }
      }
      .right {
        svg {
          height: 60px;
          width: 60px;
          padding: 5px;
        }
      }
    }

    .welcome {
      .discount {
        width: 30%;
        margin: 0 auto;
        .message {
          font-size: 1.15rem;
        }
      }
      .address_container {
        width: 36%;
        font-size: 1.45rem !important;
      }
    }

    .image {
      width: 18vw !important;
    }
  }
}

$mainColor: rgb(24, 78, 9);
$mainColorLight: rgba(24, 78, 9, 0.8);
$secondaryColor: rgb(228, 26, 26);
$secondaryColorLight: rgba(239, 34, 34, 0.5);

$mainGray: hsla(0, 0%, 84%, 0.85);

$desktopWidth: 1124px;
@mixin desktop {
  @media (min-width: #{$desktopWidth}) {
    @content;
  }
}

$phoneWidth: 460px;
@mixin phone {
  @media (max-width: #{$phoneWidth}) {
    @content;
  }
}

.card-header{
  background-color: 33,37,41, 0.5;
}
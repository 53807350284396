@import "../../global.scss";

.footer {
  height: 350px;
  width: 100vw;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  
  .contact {
    padding-top: 1.2rem;
    padding-left: 1rem;
    margin-right: 1rem;
  }

  .empowered {
    padding-top: 0.75rem;
    border-top: 1px solid $mainGray;
    margin: 0 1rem;
    font-size: 1rem;

    .content {
      a {
        text-decoration: none;
        color: white;
      }
    }
  }

  .empowered a:hover {
    color: red;
  }

  .terms{
    display: flex;
    flex-direction: row;
    padding-left: 1rem;

    .link{
      margin-right: 1rem;
      font-size: 0.85rem;
      font-style: none;
      color: $mainGray;
    }

    .cards{
      display: flex;

      img{
        border-radius: 3px;
        border: 1px solid gray;
        height: 22px;
        width: 34px;
        margin-right: 0.5rem;
      }
    }
  }
}

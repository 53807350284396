@import "../../global.scss";

.authenticateModal {
  .modal-content {
    .modal-body {
      min-height: 46vh;
    }
  }
}

.loginregisterModal{
  .container{
    padding: 0;
    .input_fields{
      display: flex;
      flex-direction: row;
      padding: 5px 0px;

      input{
        width: 100%;
        border: none;
        border-bottom: 1px solid gray;
        margin-left: 0.5rem;
      }
      input:hover{
        border: none;
        box-shadow: 0 6px 3px -3px $mainColor;

      }
    }

    .submit{
      display: flex;
      justify-content: center;
      margin-bottom: 1.25rem;
      margin-top: 2rem;

      button{
        padding: 8px;
        border: 1px solid #000;
        border-radius: 5px;
        color: white;
        font-size: 1.2rem;
        font-weight: 700;
        min-width: 50%;
      }

      .user{
        background-color: $mainColor;
      }
    }

    .register_notice{
      margin: 0.75rem 0;
      text-align: center;
      font-size: 0.85rem;
      font-weight: bold;
    }
  }
}

.LoginRegister{
  .nav-item{
    button{
      color: gray;
      font-weight: 600;
      font-size: 20px;
    }
  }

  .nav-link.active{
    color: $mainColor;
  }
}

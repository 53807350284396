@import url("https://fonts.googleapis.com/css2?family=PT+Serif&display=swap");
@import "./global.scss";

* {
  max-width: 100%;
  font-family: Arial, sans-serif;
}

.loading_details {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: $mainGray;
  z-index: 3;
}

.store_is_not_active{
  margin-top: 65px;
  margin-left: 2rem;
  margin-right: 2rem;
  padding: 0.5rem 0.75rem;
  border-radius: 5px;
  border: 2px solid gray;
  background-color: rgb(221, 221, 221);
  color: gray;
  text-align: center;
  font-weight: bold;
}

.app {
  width: 100vw;
  background-color: $mainGray;
  display: flex;
  flex-direction: column;

  .Toastify{
    .Toastify__toast-container{
      left: 50%;
      transform: translateX(-50%);
      bottom: 1em;
    }
  }

  main {
    min-height: calc(100vh - 60px - 170px);
    display: flex;
    flex-direction: column;
    padding: 0.25rem 0;
  }

  .desktop_cart_container {
    display: none;
  }
}

.not_found{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding: 0rem 1rem;
}

.menu_list{
  display: flex;

  .menu_list_button{
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    border-radius: 5px;
    margin-left: 0.25rem;

    svg{
      color: rgb(70, 70, 70);
      height: 30px;
      width: 30px;
    }
  }

  .menu_select_container{
    flex: 18;
  }
}

.menu_list_active{
  position: relative;
  min-height: 100vh;
  width: 100vw;
  top: 0;
  padding: 0 0.5rem;
  overflow-y: hidden;

  .menu_categories_list{
    margin-top: 0.5rem;
  }

  .category_container:first-child{
    border-top: 1px solid black;
  }

  .category_container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid black;
    padding: 0.3rem 0;

    .cat_name{
      display: flex;
      align-items: center;
      margin-bottom: 0;
    }

    .subcats{
      .subcat_name{
        margin-bottom: 0;
        padding: 0.5rem 1rem;
        border-bottom: 1px solid black;
      }
      .subcat_name:last-child{
        border-bottom: none;
      }
    }

    img{
      display: flex;
      height: 60px;
      width: 60px;
    }
  }
}


@include desktop {
  .store_is_not_active{
    margin-left: 24rem;
    margin-right: 24rem;
    font-size: 1.4rem;
  }

  main {
    display: flex !important;
    flex-direction: row !important;
  }
  .main_container {
    width: 64%;
    margin: 0.25rem 0.5rem;
    margin-right: 0;
  }
  .cart_popup {
    display: none !important;
  }
  .desktop_cart_container {
    display: flex !important;
    width: 36%;

    .desktop_cart {
      width: 100%;
      margin: 0.25rem 0.75rem;
      margin-left: 0;
      height: 98vh !important;
      background: white;
      position: sticky;
      top: 0.5rem;
      border: 2px solid $mainGray;
      border-radius: 8px;
      padding: 0.75rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        text-align: center;
        font-size: 1.6rem;
        flex: 1;
      }

      .cart_body {
        background-color: $mainGray;
        padding: 0.5rem;
        border-radius: 4px;
        flex: 23;
        display: flex;
        position: relative;
        width: 100%;

        .empty {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          img {
            width: 125px;
            height: 125px;
          }
        }

        .items_wrapper {
          position: absolute;
          overflow-y: auto;
          max-height: 90%;
          display: flex;
          flex-direction: column;
          flex: 12;
          width: calc(100% - 1rem);

          .cartpopup_item {
            padding: 0.5rem 0;
            margin-bottom: 0.5rem;
            padding-bottom: 0.75rem;
            border-bottom: 1px solid gray;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .left {
              flex: 11;
              display: flex;

              p {
                margin-bottom: 0;
                font-size: 14px;
              }

              .item_container {
                flex: 82%;
                display: block;

                .item {
                  display: flex;
                  align-items: center;

                  .cartpopup_itemQuantity {
                    font-size: 1rem;
                    font-weight: 700;
                  }
                  .cartpopup_itemName {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-size: 0.9rem;

                    .size {
                      font-size: 0.8rem;
                    }
                  }
                }
                .cartpopup_itemExtra {
                  font-size: 13px;
                  margin-left: 1rem;
                }
                .cartpopup_itemRequests {
                  font-size: 14px;
                }
              }

              .prices_container {
                width: 18%;

                .prices {
                  display: flex;
                  flex-direction: column;

                  .cartpopup_itemPrice {
                    font-size: 1rem;
                    display: flex;
                    justify-content: flex-end;
                  }

                  .cartpopup_extraPrice {
                    font-size: 13px;
                    text-align: end;
                  }

                  .empty {
                    color: rgb(230, 230, 230);
                  }
                }
              }
            }

            .right {
              flex: 1;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;

              .cartpopup_deleteItemButton {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 6px;
                height: 20px;
                width: 20px;

                svg {
                  height: 20px;
                  width: 20px;
                  color: red;
                }
              }
            }
          }
        }

        .checkout_container {
          z-index: 2;
          height: 10%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0rem 0.5rem;
          border-radius: 5px;
          border: 8px double black;
          background-color: white;
          position: absolute;
          bottom: 0;
          left: 0;
          margin: 0.5rem;
          width: calc(100% - 1rem);

          .checkout_text {
            display: flex;
            align-items: center;
            justify-content: center;

            .text {
              margin-bottom: 0;
              font-size: 1.4rem;
              font-weight: 900;
              color: black;
            }

            svg {
              height: 1.5rem;
              width: 1.5rem;
              color: black;
              margin-left: 0.5rem;
            }
          }

          .total {
            margin-bottom: 0;
            font-size: 1.1rem;
            font-weight: 900;
            color: black;
          }
        }
      }
    }
  }
}

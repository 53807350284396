@import "../../global.scss";

.cart_popup {
  width: 96%;
  background-color: $mainColor;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  flex-direction: column-reverse;
  margin: 0 2% 3% 2%;

  .card-header {
    height: 100px;
    width: 100%;
    padding: 0.35rem 0.65rem;

    .checkout_container {
      z-index: 5;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0rem 0.5rem;
      border-radius: 5px;
      border: 8px double $mainColor;
      background-color: white;

      .checkout_text {
        display: flex;
        align-items: center;
        justify-content: center;

        .text {
          margin-bottom: 0;
          font-size: 1.25rem;
          font-weight: 900;
          color: $mainColor;
        }

        svg {
          height: 2rem;
          width: 2rem;
          color: $mainColor;
          margin-left: 0.5rem;
        }
      }

      .total {
        margin-bottom: 0;
        font-size: 22px;
        font-weight: 900;
        color: $mainColor;
      }
    }

    .viewcart_container {
      display: flex;
      flex-direction: column;
      height: 90%;
      width: 100%;

      .text {
        align-items: center;
        color: #fff;
        display: flex;
        flex: 4 1;
        font-size: 26px;
        font-weight: 900;
        justify-content: center;
        margin-bottom: 0;
      }
      .cart_data {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .data {
          align-items: center;
          color: #fff;
          display: flex;
          font-size: 20px;
          justify-content: center;
          margin-bottom: 0;
        }

        .cartLength {
          align-items: center;
          color: #fff;
          display: flex;
          font-size: 20px;
          justify-content: center;
          margin-bottom: 0;
        }
      }
    }
  }

  .card-body {
    width: 100%;
    height: 80dvh;
    overflow-y: auto;
    padding: 0.95rem 0.65rem;
    padding-bottom: 0.2rem;

    .cartpopup_container {
      min-height: 100%;
      padding: 4px 6px;
      background-color: white;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      border: 2px solid $mainGray;

      .header_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 1rem 0.5rem;

        .mycart {
          margin-bottom: 0;
          font-weight: 700;
        }

        .close_cart {
          display: flex;
          justify-content: flex-end;

          svg {
            height: 2rem;
            width: 2rem;
            color: $secondaryColor;
          }
        }
      }

      .items_wrapper {
        background-color: rgb(230, 230, 230);
        padding: 1rem 0.5rem;
        min-height: 66vh;
        border-radius: 5px;

        .reward_total {
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
        }
        
        .cartpopup_item {
          margin-bottom: 1rem;
          padding-bottom: 0.75rem;
          border-bottom: 1px solid gray;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .left {
            flex: 11;
            display: flex;

            p {
              margin-bottom: 0;
              font-size: 14px;
            }

            .item_container {
              flex: 82%;
              display: block;

              .item {
                display: flex;
                align-items: center;

                .cartpopup_itemQuantity {
                  font-size: 1rem;
                  font-weight: 700;
                }
                .cartpopup_itemName {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  font-size: 0.9rem;

                  .size {
                    font-size: 0.8rem;
                  }
                }
              }
              .cartpopup_itemExtra {
                font-size: 13px;
                margin-left: 1rem;
              }
              .cartpopup_itemRequests {
                font-size: 14px;
              }
            }

            .prices_container {
              width: 18%;

              .prices {
                display: flex;
                flex-direction: column;

                .cartpopup_itemPrice {
                  font-size: 1rem;
                  display: flex;
                  justify-content: flex-end;
                }

                .cartpopup_extraPrice {
                  font-size: 13px;
                  text-align: end;
                }

                .empty {
                  color: rgb(230, 230, 230);
                }
              }
            }
          }

          .right {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            .cartpopup_deleteItemButton {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 6px;
              height: 20px;
              width: 20px;

              svg {
                height: 20px;
                width: 20px;
                color: $secondaryColor;
              }
            }
          }
        }
      }
    }
  }
}

@import "../../global.scss";

.terms_container {
  body {
    background-color: $mainGray;
    padding: 0.5rem;
    padding-bottom: 1rem;
  }
  header,
  section,
  footer {
    margin-bottom: 20px;
  }

  h1,
  h2 {
    color: black;
  }

  p {
    color: black;
  }

  .title {
    font-size: 1.9rem;
  }
}

@import "../../global.scss";

.account_page {
  min-height: calc(100vh - 250px);
  .account_container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    width: 100vw;

    .AccountTabs {
      width: 100%;
      align-items: center;
      justify-content: center;

      .nav-item {
        button {
          font-size: 20px;
          color: black;
        }
        .active {
          color: $mainColor;
        }
      }
    }
    .tab-content {
      width: 100%;

      .tab-pane {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .orders_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-width: 80vw;
        width: 100%;
        margin-top: 0.5rem;

        .order_dropdown {
          width: 100%;
          margin-bottom: 1rem;

          .card-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0.7rem 1rem;

            .order_header_details {
              font-size: 20px;
              font-weight: 700;
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              .order_header_details_container {
                display: flex;
                align-items: center;

                .orderNumber {
                  font-size: 22px;
                  font-weight: 900;
                  margin-right: 0.5rem;
                  color: $mainColor;
                }
              }
              .orderNumber {
                flex: 1;
                font-size: 16px;
                font-weight: 900;
                margin-right: 0.5rem;
                color: $mainColor;
              }
              .orderType {
                flex: 1;
                font-size: 16px;
                font-weight: 900;
                margin-right: 0.75rem;
                color: $mainColor;
              }
              .date {
                font-size: 16px;
                flex: 8;
              }
              .order_status {
                border-radius: 5px;
                color: white;
                margin-left: 1rem;
                font-size: 14px;
                padding: 0.4rem;
                font-weight: 700;
              }
              .error {
                background-color: $secondaryColorLight;
                border: 2px solid $secondaryColor;
              }
              .success {
                background-color: $mainColorLight;
                border: 2px solid $mainColor;
              }
            }

            svg {
              height: 26px;
              width: 26px;
            }

            .collapsed {
              transform: rotate(180deg);
            }
          }

          .card-body {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .delivery_address {
              width: 96%;
              border-bottom: 1px solid black;
              padding-bottom: 0.5rem;
            }

            .order {
              display: flex;
              flex-direction: column;
              min-height: 6rem;
              width: 96%;

              .item {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                padding: 0.5rem 0;
                border-top: dashed 1px rgb(141, 141, 141);

                .left {
                  display: flex;
                  flex-direction: column;

                  .item_name {
                    display: flex;
                    flex-direction: row;
                  }

                  .item_extra {
                    padding-left: 2rem;

                    .special {
                      color: green;
                    }
                  }

                  .specialRequest {
                    font-weight: 700;
                  }
                }
              }
              .item:first-child {
                border-top: none;
              }

              .order_totals {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                padding: 0.5rem 0;
                border-top: solid 1px black;

                .right {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                }
              }
            }
            .order_footer {
              width: 96%;
              height: 4rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 4rem 2rem;
              margin-top: 0.25rem;
              border-top: 1px solid black;

              button {
                border: none;
                color: white;
                font-weight: 800;
                font-size: 1.5rem;
                border-radius: 40px;
                padding: 1.25rem 1.25rem;
              }
              .cancel {
                background-color: red;
              }
              .complete {
                background-color: green;
              }
            }
          }
          .collapse {
            display: none;
          }
        }
      }
    }

    .account_inputs {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      width: 80%;

      input {
        margin-bottom: 1rem;
      }
    }

    .submit {
      margin-top: 1rem;
      padding: 0.75rem 1.75rem;
      background-color: $mainColorLight;
      color: white;
      font-size: 18px;
      font-weight: 600;
      border-radius: 5px;
      border: 4px solid $mainColor;
    }
  }
}

.recentOrders {
  margin: 0 2rem;

  .order {
    .card-header {
      align-items: center;
      padding: 0.5rem 0.25rem;

      .order_container{
        width: 76%;
      }

      .item{
        font-size: 15px;
        margin-right: 0.5rem;
        font-weight: 600;
        width: 60%;
      }
      .otherItems{
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 15px;
      }

      .date{
        color: gray;
        font-size: 12px;
      }

      .viewDetails{
        font-size: 15px;
        color: rgb(35, 118, 252);
      }

      button {
        background-color: $secondaryColorLight;
        border: 2px solid $secondaryColor;
        border-radius: 3px;
        color: white;
        font-size: 13px;
        padding: 0.25rem 0.5rem;
      }
    }

    .card-body{
      padding: 0.5rem 1rem;
      padding-top: 0;

      .item{
        margin-top: 0.25rem;
        padding-top: 0.25rem;
        border-top: dashed 1px gray;
      }
      .item:first-child{
        border: none;
      }
      .extras{
        .extraItem{
          font-size: 15px;
        }
      }

      .requests{
        font-size: 15px;
        color: $mainColor;
      }
    }
  }
}

@import "../../global.scss";

.openAdminPortalMessage {
  padding: 1rem;
}
.openAdminPortalMessage .modal-dialog {
  max-width: 80vw !important;
  height: 400px !important;
}
.openAdminPortalMessage .modal-body {
  height: 400px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 4rem 4rem;
}
.openAdminPortalMessage .modal-body .body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.openAdminPortalMessage .admin_welcome {
  font-size: 2.8rem;
  text-align: center;
}
.openAdminPortalMessage .admin_message {
  font-size: 1.6rem;
  text-align: center;
}

.admin {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white !important;

  .nav-tabs {
    border-bottom: none !important;
  }

  .newOrderMessage {
    position: fixed;
    z-index: 5;
    top: 25%;
    background-color: rgb(211, 211, 211);
    color: black;
    width: 60vw;
    height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 2px solid black;
  }

  .adminTabsContainer {
    display: block;
    width: 98%;
    padding: 0.75rem 0.25rem;
    background-color: white;
    border-radius: 5px;
    background-color: rgba(245, 245, 245, 0.5);
    
    .AdminTabs {
      flex: 4;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 0.5rem;
      margin-bottom: 0;

      li {
        display: flex;
        flex: 3;
        border-bottom: 1px solid gray;

        button {
          width: 100%;
          font-size: 1.2rem;
          color: black;
          font-weight: 500;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 0px;
          border-bottom-left-radius: 0px;
          border-top-left-radius: 8px;
          padding: 0.45rem 0.25rem;
          padding-left: 0.5rem;
          background-color: rgba(214, 214, 214, 0.85);
        }
        .active {
          font-weight: 700;
        }
      }
    }
    .tab-content {
      flex: 17;
      margin: 0 0rem;
      margin-bottom: 1.5rem;
      padding: 0;

      .orders_container {
        min-height: 60vh;
        width: 96%;
        margin: 0 auto;

        .order {
          width: 100%;
          margin-bottom: 1rem;

          .order_header {
            background-color: $mainGray;
            border: 1px solid gray;
            display: flex;
            align-items: center;

            .orderNumber {
              color: $mainColor;
              margin-right: 0.3rem;
            }
            .orderType {
              color: $mainColor;
            }
            .orderDetails {
              margin-left: 0.75rem;
              padding-top: 0.1rem;
            }
          }

          .card-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid gray;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px;
            padding: 0.5rem 1rem;

            .order_items {
              display: flex;
              flex-direction: column;
              min-height: 6rem;
              width: 96%;

              .item {
                padding: 0.5rem 0;
                border-top: dashed 1px rgb(141, 141, 141);

                .left {
                  display: flex;
                  flex-direction: column;
                  .item_name {
                    display: flex;
                    flex-direction: row;
                  }
                  .item_extra {
                    padding-left: 2rem;
                    .special {
                      color: green;
                    }
                  }

                  .specialRequest {
                    font-weight: 700;
                  }
                }
              }
              .item:first-child {
                border-top: none;
              }

              .order_totals {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                padding: 0.5rem 0;
                border-top: solid 1px black;

                .right {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;

                  .price {
                    display: flex;

                    .oldPrice {
                      margin-right: 0.4rem;
                    }
                  }
                }
              }
            }
            .order_footer {
              width: 96%;
              height: 4rem;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 4rem 4rem;
              margin-top: 0.25rem;
              border-top: 1px solid black;

              button {
                border: none;
                color: white;
                font-weight: 800;
                font-size: 1.2rem;
                border-radius: 10px;
                padding: 1.25rem 1.25rem;
                margin: 0 4rem;
              }
              .cancel {
                background-color: rgba(255, 0, 0, 0.75);
                border: 4px solid red;
              }
              .complete {
                background-color: rgba(0, 128, 0, 0.75);
                border: 4px solid green;
              }
            }

            .delivery_address {
              padding: 0.25rem 0;
              margin-bottom: 0.5rem;
              width: 50%;
              background-color: $mainGray;
              border: 1px solid gray;

              .address_label {
                margin-right: 0.25rem;
              }
            }
          }
        }
      }
    }
  }
}

.not_authorized {
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.areYouSureModal {
  margin: 10vh 0;

  .buttons {
    display: flex;
    width: 100%;
    height: 20vh;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    button {
      margin: 2rem;
      color: white;
      font-weight: 600;
      font-size: 30px;
      width: 8rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
    }
    .yes {
      background-color: green;
    }
    .no {
      background-color: red;
    }
  }
}

.orderHistory_container {
  .no-focus-outline {
    outline: none;
  }

  .filter_by_dates{
    align-items: center;
    .filter_by_dates_submit{
      border: 1px solid black;
      background-color: white;
      color: black;
      font-size: 1.1rem;
      padding: 0.1rem 0.5rem;
      margin-left: 1rem;
      border-radius: 5px;
    }
  }

  .card {
    margin-bottom: 0.75rem;
    border: 1px solid gray;

    .card-header {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;

      .left {
        flex: 9;
        display: flex;

        .orderNumber {
          font-size: 1rem;
          font-weight: 700;
          color: $mainColor;
        }
        .orderType {
          font-size: 1rem;
          font-weight: 700;
          color: $mainColor;
          margin-left: 6px;
        }
        .orderName {
          margin-top: 0.2rem;
          font-size: 0.9rem;
          font-weight: 700;
          margin-left: 6px;
        }
        .total {
          margin-top: 0.2rem;
          font-size: 0.9rem;
          margin-left: 6px;
          font-weight: 700;
        }
        .edited {
          color: orange;
        }
        .cancelled {
          color: red;
        }
        .requestedDate {
          margin-top: 0.2rem;
          font-size: 0.9rem;
          margin-left: 6px;
          font-weight: 700;
        }
      }
      .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .status_tag {
          padding: 0.125rem;
          border-radius: 5px;
          color: white;
          font-size: 0.55rem;
          font-weight: 800;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .complete {
          background-color: rgba(0, 128, 0, 0.6);
          border: 2px solid green;
        }
        .cancelled {
          background-color: rgba(255, 0, 0, 0.6);
          border: 2px solid red;
        }
        .edited {
          background-color: rgba(255, 165, 0, 0.6);
          border: 2px solid orange;
          margin-left: 0.25rem;
        }
      }
    }
  }

  .month_selection {
    display: block;
    margin-bottom: 1rem;

    .year_container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5rem;

      .year {
        margin: 0 0.5rem;
        color: black;
        font-size: 1.4rem;
      }

      svg {
        height: 1.2rem;
        width: 1.2rem;
      }
    }

    .month_container {
      display: flex;
      overflow: auto;

      .monthButton {
        flex: 1;
        border: 2px solid gray;
        background-color: $mainGray;
        border-radius: 5px;

        .month {
          .label {
            font-size: 0.9rem;
          }
        }
      }
    }

    .selected {
      background-color: $secondaryColorLight !important;
      color: white;
      border: 2px solid $secondaryColor !important;
      font-weight: 700;
    }
  }

  .order_summary {
    margin-bottom: 0.5rem;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;

    .selectedMonth {
      font-size: 1.4rem;
    }
    .numberOfOrders {
      font-size: 1.2rem;
    }
    .chargedAmount {
      font-size: 1.2rem;
    }
  }
}

.store_settings {
  display: block;
  padding: 0.5rem;
  border-radius: 8px;
  border: 1px solid gray;
  background-color: rgb(230, 230, 230);

  .locked_container {
    border: 1px solid gray;
    border-radius: 5px;

    .locked {
      background-color: #eeeeee;
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
      margin: 1rem;

      .field {
        padding: 0.75rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1.5rem;

        .toggle-button {
          padding: 0.4rem 0.8rem;
          border: none;
          cursor: pointer;
          font-size: 1.2rem;
          border-radius: 5px;
          font-weight: 700;
          margin-top: 4px;
        }

        .toggle-button.active {
          background-color: green;
          color: white;
        }

        .toggle-button.inactive {
          background-color: red;
          color: white;
        }
      }
    }
    .open {
      .field {
        padding: 0.75rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1.5rem;

        .toggle-button {
          padding: 0.4rem 0.8rem;
          border: none;
          cursor: pointer;
          font-size: 1.2rem;
          border-radius: 5px;
          font-weight: 700;
          margin-top: 4px;
        }

        .toggle-button.active {
          background-color: green;
          color: white;
        }

        .toggle-button.inactive {
          background-color: red;
          color: white;
        }
      }
    }
  }

  .orderETA_container {
    border: 1px solid gray;
    border-radius: 5px;
    min-height: 34vh;
    .order_eta {
      display: flex;
      margin: 1rem 0;

      .ETA {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .ETA_title {
          font-size: 22px;
          margin-bottom: 0.5rem;
        }

        .ETA_inputs {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 15px;

          .hyphen {
            margin-right: 0.5rem;
          }

          input {
            border: none;
            width: 60px;
            height: 34px;
            margin-right: 0.5rem;
            border-radius: 5px;
            text-align: center;
            border: 1px solid black;
          }
        }
      }
    }
    .submit_container {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      .submit {
        width: 8rem;
        height: 3rem;
        background-color: $mainColor;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        border-radius: 5px;
        font-weight: 600;
        font-size: 18px;
      }
    }
  }

  .store_hours,
  .delivery_store_hours {
    width: 100%;
    margin-bottom: 1rem;

    .card-header {
      border: 1px solid gray;
      font-size: 20px;
      font-weight: 700;
    }
    .card-body {
      border: 1px solid gray;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      min-height: 40vh;
      padding: 0.75rem;

      .day {
        padding: 0.3rem 0;
        display: flex;
        align-items: center;

        .dayofWeek {
          flex: 2.5;
          font-size: 1.1rem;
        }
        .open {
          flex: 3;
          display: flex;
          justify-content: center;
          border: 1px solid black;
          border-radius: 5px;
          height: 2rem;
        }
        .open:disabled {
          background-color: $mainGray;
        }
        .close {
          flex: 3;
          display: flex;
          justify-content: center;
          border: 1px solid black;
          border-radius: 5px;
          height: 2rem;
        }
        .close:disabled {
          background-color: $mainGray;
        }
        .changeButton {
          flex: 2;
          display: flex;
          justify-content: center;

          button {
            padding: 0.15rem 0.25rem;
            background-color: $secondaryColorLight;
            border: 2px solid $secondaryColor;
            border-radius: 5px;
            color: white;
          }
          button:disabled {
            background-color: $mainGray;
            color: gray;
            border: 2px solid gray;
          }
        }
      }

      .submit {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 1.5rem;

        button {
          padding: 0.5rem 1rem;
          border: none;
          background-color: $mainColor;
          border-radius: 5px;
          color: white;
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
  }
  .store_hours {
    border: 1px solid gray;
    min-height: 34vh;
    border-radius: 5px;
  }
  .delivery_store_hours {
    border: 1px solid gray;
    min-height: 34vh;
    border-radius: 5px;
  }
  .store_closures {
    border: 1px solid gray;
    min-height: 34vh;
    border-radius: 5px;

    .card-body {
      min-height: 40vh;
      display: flex;
      flex-direction: column;
      align-items: center;

      .closures {
        .day {
          font-size: 1.3rem;
          font-weight: 700;
        }
      }

      .add_closure {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;

        input {
          height: 2rem;
          border: 1px solid black;
          border-radius: 5px;
        }

        .submit {
          margin-top: 1rem;

          button {
            width: 8rem;
            padding: 0.75rem 0.5rem;
            border-radius: 5px;
            border: none;
            background-color: $mainColor;
            margin-bottom: 1rem;
            color: white;
            font-weight: 700;
          }

          .submit_cancel {
            display: flex;
            flex-direction: column;
            align-items: center;

            .cancel {
              width: 6rem;
              background-color: red;
            }
          }
        }
      }
    }
  }

  .store_details {
    .card-header {
      border: 1px solid gray;
      font-size: 20px;
      font-weight: 700;
    }
    .card-body {
      border: 1px solid gray;
      min-height: 34vh;
      border-radius: 5px;

      .input_fields {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.25rem;

        input {
          border: 1px solid black;
          border-radius: 5px;
        }
      }
    }

    .submit {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1.5rem;

      button {
        padding: 0.5rem 1rem;
        border: none;
        background-color: $mainColor;
        border-radius: 5px;
        color: white;
        font-size: 18px;
        font-weight: 700;
      }
    }
  }

  .AdminSettingsTabs {
    display: flex;
    flex-direction: row;
    width: 100%;

    .nav-item {
      margin-bottom: 1px;
      flex: 1;
      border-top-left-radius: none !important;

      button {
        font-size: 1.05rem;
        width: 100%;
        text-align: center;
        color: black;
        border-bottom: 1px solid black;
        border-radius: 0;
        padding: 0.5rem;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        background-color: rgba(214, 214, 214, 0.85);
      }
      .active {
        font-weight: 700;
      }
    }
  }
  .tab-content {
    padding: 0 !important;
  }

  .StoreHoursTabs {
    display: flex;
    justify-content: center;
    .nav-item {
      width: 44%;
      button {
        width: 100%;
        font-size: 1.3rem;
        color: black;
      }
      .active {
        background-color: rgba(214, 214, 214, 0.85);
        color: $mainColor;
        font-size: 1.3rem;
        font-weight: 700;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        border-bottom: 1px solid black;
      }
    }
  }
}

@include desktop {
  .orderETA_container {
    .ETA_inputs {
      font-size: 18px !important;

      .hyphen {
        margin-right: 0.75rem !important;
      }

      input {
        width: 80px !important;
        height: 40px !important;
        margin-right: 0.75rem !important;
      }
    }
  }
}

@include phone {
  .openAdminPortalMessage {
    padding: 1rem;
  }
  .openAdminPortalMessage .modal-body {
    padding: 1rem !important;
  }
  .openAdminPortalMessage .modal-dialog {
    max-width: 100vw !important;
    height: 40vh !important;
  }
  .openAdminPortalMessage .admin_welcome {
    font-size: 2rem;
    text-align: center;
  }
  .openAdminPortalMessage .admin_message {
    font-size: 1rem;
    text-align: center;
  }
  .openAdminPortalMessage .modal-body .body {
    height: 200px;
  }

  .adminTabsContainer{
    display: block !important;
    padding: 0.5rem !important;

    .AdminTabs{
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-right: 0 !important;

      .nav-item{
        button{
          font-size: 0.9rem !important;
          text-align: center !important;
          border-bottom-right-radius: 0px !important;
          border-top-left-radius: 8px !important;
        }
      }
    }
    .tab-content{
      padding: 0 !important;
    }
  }
  .month_selection {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .store_settings {
    display: flex;
    flex-direction: column;

    .tab-content {
      margin: 0;
      padding: 0;
      width: 100%;
    }

    .AdminSettingsTabs {
      display: flex !important;

      li {
        button {
          font-size: 0.85rem;
          padding: 0.3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
        }
      }
    }

    .orderETA_container {
      .order_eta {
        display: block;
      }
    }
  }

  .StoreHoursTabs {
    button {
      font-size: 1rem !important;
    }
  }

  .store_hours,
  .delivery_store_hours {
    .hours {
      .day {
        flex-direction: column;
        border-bottom: 1px solid gray;
      }
    }
  }
}

@include desktop {
  .left {
    .orderNumber {
      font-size: 1.4rem !important;
    }
    .orderType {
      font-size: 1.4rem !important;
    }
    .orderName {
      font-size: 1.2rem !important;
    }
    .total {
      font-size: 1.2rem !important;
    }
    .requestedDate {
      font-size: 1.2rem !important;
    }
  }
  .right {
    .status_tag {
      font-size: 1rem !important;
    }
  }

  .order_footer {
    width: 96% !important;
    height: 4rem !important;
    padding: 4rem 4rem !important;
    margin-top: 0.25rem !important;

    button {
      font-size: 1.5rem !important;
      padding: 1.25rem 1.25rem !important;
    }
  }
}
